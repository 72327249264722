import React from 'react';
import './style.css';


export const Card_5 = () => {
  return (
      <>
        {/* CONTENEDOR PRINCIPAL */}
        <div className='card_5-container'>
        
            
                    <div className='card_5-banner-tilte'>
                        " PROTEJE EL MEDIO AMBIENTE, MIENTRAS PRODUCES ENERGÍA MÁS EFICIENTEMENTE "
                    </div>

            
        </div>
      </>
  )
}
